import React from 'react';
import { takeEvery, spawn, all, put } from 'redux-saga/effects';
import { notification } from 'antd';
import { api } from 'api';

import actions from 'redux/actions';

import messages, { getErrorMessagebyServerName } from 'utils/messages';
import { USER_ROLES } from 'utils/constants';

function* callFindPartnersGroups({ payload }) {
  try {
    const response = yield api.getPartnerGroups({
      ...payload,
      brand: process.env.REACT_APP_BRAND,
    });

    yield put(actions.findPartnersGroups.success(response.data));
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.findPartnersGroups.failure(errorMessage));
  }
}

function* callFindPartnersForGroups({ payload }) {
  try {
    const { type, ...payloadWithoutType } = payload;

    if (type) {
      payloadWithoutType.regSource = type;
    }

    const response = yield api.getUsers({
      role: [USER_ROLES.BROKER],
      ...payloadWithoutType,
    });

    yield put(actions.findPartnersForGroups.success(response.data));
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.findPartnersForGroups.failure(errorMessage));
  }
}

function* callCreatePartnersGroup({ payload }) {
  try {
    const { settings, ...data } = payload;
    const response = yield api.createPartnerGroups({
      ...data,
      brand: process.env.REACT_APP_BRAND,
    });

    yield put(actions.createPartnersGroup.success(response.data));

    const { id } = response.data;

    if (id) {
      yield all([
        ...(settings
          ? [yield api.createCommissionSettings({ groupId: id, settings })]
          : []),
        ...(data.cpaQualifier
          ? [
              yield api.createCpaQualifier({
                ...data.cpaQualifier,
                groupId: id,
              }),
            ]
          : []),
        ...(data.cpaQualifier?.tiers?.length
          ? [yield api.createCpaTier(id, data.cpaQualifier.tiers)]
          : []),
      ]);
    }

    notification.success({ message: messages.SUCCESS.CREATE_PARTNERS_GROUP });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Create partner error',
      description: <span>{errorMessage}</span>,
      duration: 4,
    });

    yield put(actions.createPartnersGroup.failure(errorMessage));
  }
}

function* callPatchPartnersGroup({ payload }) {
  try {
    const { settings, ...data } = payload;
    const { id } = data;

    if (settings) {
      yield api.createCommissionSettings({ groupId: id, settings });
      yield put(actions.loadSettings.success(settings));
    }

    const response = yield api.updatePartnerGroups(id, data);

    yield all([
      ...(data.cpaQualifier
        ? [
            yield api.createCpaQualifier({
              ...data.cpaQualifier,
              groupId: id,
            }),
          ]
        : []),
      ...(data.cpaQualifier?.tiers?.length
        ? [yield api.createCpaTier(id, data.cpaQualifier.tiers)]
        : []),
    ]);
    yield put(actions.patchPartnersGroup.success(response.data));

    notification.success({ message: messages.SUCCESS.PATCH_PARTNERS_GROUP });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Update partner error',
      description: <span>{errorMessage}</span>,
      duration: 4,
    });

    yield put(actions.patchPartnersGroup.failure(errorMessage));
  }
}

function* callRemovePartnersGroup({ payload: { groupIds, callBack } }) {
  try {
    const response = yield api.deletePartnerGroups({ data: { groupIds } });

    yield put(actions.removePartnersGroup.success(response.data));

    notification.success({ message: messages.SUCCESS.REMOVE_PARTNERS_GROUP });

    callBack();
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Remove partners group error',
      description: <span>{errorMessage}</span>,
      duration: 7,
    });
    yield put(actions.removePartnersGroup.failure(errorMessage));
  }
}

function* callLoadAllPartnersGroups({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const response = yield api.getPartnerGroups(requestData);

    if (response) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* callLoadCpaQualifier({ payload }) {
  try {
    const response = yield api.getCpaQualifier(payload);

    yield put(actions.loadCpaQualifier.success(response.data));
  } catch (error) {
    yield put(actions.loadCpaQualifier.failure(error));
  }
}

function* callLoadCpaTiers({ payload }) {
  try {
    const response = yield api.getTiers(payload.groupId);

    yield put(actions.loadTiers.success(response.data));
  } catch (error) {
    yield put(actions.loadTiers.failure(error));
  }
}

function* watchAuth() {
  yield takeEvery(actions.findPartnersGroups.request, callFindPartnersGroups);
  yield takeEvery(
    actions.findAllPartnersGroups.request,
    callLoadAllPartnersGroups,
  );
  yield takeEvery(
    actions.findPartnersForGroups.request,
    callFindPartnersForGroups,
  );
  yield takeEvery(actions.createPartnersGroup.request, callCreatePartnersGroup);
  yield takeEvery(actions.loadCpaQualifier.request, callLoadCpaQualifier);
  yield takeEvery(actions.loadTiers.request, callLoadCpaTiers);
  yield takeEvery(actions.patchPartnersGroup.request, callPatchPartnersGroup);
  yield takeEvery(actions.removePartnersGroup.request, callRemovePartnersGroup);
}

export default function* authSaga() {
  yield all([watchAuth].map(spawn));
}
