import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import BackgroundWrapper from 'components/Common/BackgroundWrapper';
import DropdownSelect from 'components/Common/Input/DropdownSelect';

import { TABLE_DATA_INDEX } from 'utils/tableSchema';

import styles from './styles.module.less';

const DEFAULT_OPTIONS = { value: null, label: 'Not selected' };

const SelectorCell = ({
  type,
  currentValue,
  record,
  isShowSearch,
  getOptionsFromStore,
  onChange,
  onSearch,
}) => {
  const availableValues = useSelector(getOptionsFromStore);

  const onClickPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onSelectChange = (value) => {
    onChange(record, { [type]: value });
  };

  switch (type) {
    case TABLE_DATA_INDEX.GROUP:
    case TABLE_DATA_INDEX.MANAGER:
    case TABLE_DATA_INDEX.ACCESS:
    case TABLE_DATA_INDEX.EDITABLE_STATUS:
    case TABLE_DATA_INDEX.PARTNER_IB_ID:
    case TABLE_DATA_INDEX.MASTER_IB_ID:
      return (
        <DropdownSelect
          value={currentValue}
          showSearch={isShowSearch}
          onClick={onClickPropagation}
          onChange={onSelectChange}
          onSearch={onSearch}
          filterOption={
            isShowSearch &&
            ((input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1)
          }
          className={clsx(styles.selectorGrey, styles.selector)}
          options={[DEFAULT_OPTIONS, ...availableValues]}
        />
      );
    case TABLE_DATA_INDEX.ROLE:
      return (
        <DropdownSelect
          value={currentValue}
          showSearch={isShowSearch}
          onClick={onClickPropagation}
          onChange={onSelectChange}
          className={clsx(styles.selectorGrey, styles.selector)}
          options={availableValues}
        />
      );
    case TABLE_DATA_INDEX.STATUS:
      const value = currentValue.toLowerCase();
      const label =
        availableValues.find((item) => item.value === currentValue)?.label ||
        ' ';

      return (
        <DropdownSelect
          showSearch={isShowSearch}
          onClick={onClickPropagation}
          onChange={onSelectChange}
          value={
            <BackgroundWrapper
              text={label}
              icon="circle"
              className={styles.selectorBackground}
            />
          }
          className={clsx(styles[value], styles.selector)}
          options={availableValues}
        />
      );
    default:
      console.log(new Error('error in TableColSelector.js'));

      return null;
  }
};

SelectorCell.defaultProps = {
  type: '',
  isShowSearch: false,
  currentValue: null,
  onChange: () => null,
  onSearch: () => null,
};

SelectorCell.propTypes = {
  type: PropTypes.string,
  currentValue: PropTypes.string,
  record: PropTypes.object,
  isShowSearch: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  getOptionsFromStore: PropTypes.func.isRequired,
};

export default SelectorCell;
