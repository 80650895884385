import { utils, writeFile } from 'xlsx';
import moment from 'moment';
import {
  HOUR_MIN_FORMAT,
  HOUR_MIN_SEC_DATE_FORMAT,
  MDE_TIME_FORMAT,
  MONTH_DAY_YEAR_HOUSE_MIN_FORMAT,
} from './timeConstants';
import { EXPORT_DATA_TYPES, EXPORT_MENU_ITEMS } from './constants';
import { COUNTRY_CODES_MAP } from './countries';

const prepareToExport = (data, comparator, fields, processor) =>
  data.reduce((acc, item) => {
    if (comparator && comparator(item)) {
      acc.push(
        fields.map((field) => {
          if (processor && processor[field]) {
            return processor[field](item[field]);
          }

          return item[field];
        }),
      );
    }

    return acc;
  }, []);

const prepareObjectToExport = (source) =>
  source.map(({ name, data }) => {
    const series = data.reduce(
      (acc, { x, y }) => ({
        ...acc,
        [moment(x).format(MONTH_DAY_YEAR_HOUSE_MIN_FORMAT)]: y,
      }),
      {},
    );

    return {
      metrics: name,
      ...series,
    };
  });

const exportArray = (data, filename, fileExtension) => {
  const workSheet = utils.aoa_to_sheet(data);
  const workBook = {
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ['data'],
  };

  writeFile(workBook, `${filename}.${fileExtension}`, {
    bookType: fileExtension,
    type: 'array',
  });
};

const exportObject = (data, filename, fileExtension) => {
  const workSheet = utils.json_to_sheet(data);
  workSheet.A1.v = 'Metric';

  const workBook = {
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ['data'],
  };

  writeFile(workBook, `${filename}.${fileExtension}`, {
    bookType: fileExtension,
  });
};

const exportDataProcessors = {
  commissions: {
    openTime: (value) => moment(value).format(HOUR_MIN_FORMAT),
  },
  client: {
    tradeDate: (value) => moment(value).format(MDE_TIME_FORMAT),
    firstDate: (value) => moment(value).format(MDE_TIME_FORMAT),
    percentChanges: (value) => value * 100 + '%',
  },
  trades: {
    openTime: (value) => moment(value).format(HOUR_MIN_FORMAT),
    closeTime: (value) => moment(value).format(HOUR_MIN_FORMAT),
  },
  partners: {
    created: (value) => moment(value).format(MDE_TIME_FORMAT),
    lastLogin: (value) => moment(value).format(MDE_TIME_FORMAT),
  },
  management: {
    lastLogin: (value) => moment(value).format(MDE_TIME_FORMAT),
    date: (value) => moment(value).format(MDE_TIME_FORMAT),
  },
  allPartners: {
    registered: (value) => moment(value).format(MDE_TIME_FORMAT),
  },
  reports: {
    registrationDate: (value) => moment(value).format(MDE_TIME_FORMAT),
    approvalDate: (value) => moment(value).format(MDE_TIME_FORMAT),
    firstDeposit: (value) => moment(value).format(MDE_TIME_FORMAT),
    commissionDate: (value) => moment(value).format(MDE_TIME_FORMAT),
    openTime: (value) => moment(value).format(HOUR_MIN_SEC_DATE_FORMAT),
    closeTime: (value) => moment(value).format(HOUR_MIN_SEC_DATE_FORMAT),
    country: (value) => COUNTRY_CODES_MAP[value]?.value,
    bsUserCountry: (value) => COUNTRY_CODES_MAP[value]?.value,
  },
};

const getExportData = (
  exportType,
  sourceList,
  exportFields,
  processorName,
  header,
) => {
  switch (exportType) {
    case EXPORT_DATA_TYPES.TABLE:
      const data = prepareToExport(
        sourceList,
        () => true,
        exportFields,
        exportDataProcessors[processorName],
      );

      return [header, ...data];
    case EXPORT_DATA_TYPES.VOLUME:
      return exportFields.map((label, index) => [
        label,
        `${sourceList[index]}%`,
      ]);
    case EXPORT_DATA_TYPES.OVERVIEW:
      return prepareToExport(sourceList, (item) => item.enabled, [
        'title',
        'totalCount',
      ]);
    case EXPORT_DATA_TYPES.PERFORMANCE:
      return prepareObjectToExport(sourceList);
    case EXPORT_DATA_TYPES.CUSTOMER_IDENTITY:
      return prepareToExport(sourceList, () => true, ['name', 'value']);
    default:
      return [];
  }
};

export const exportTable = (
  fileName,
  exportType,
  sourceList,
  exportFields,
  header,
  fileExtension,
  processorName,
) => {
  const exportData = getExportData(
    exportType,
    sourceList,
    exportFields,
    processorName,
    header,
  );

  if (fileExtension) {
    switch (exportType) {
      case EXPORT_DATA_TYPES.PERFORMANCE:
        exportObject(exportData, fileName, fileExtension);

        break;
      default:
        exportArray(exportData, fileName, fileExtension);
    }
  } else {
    navigator.clipboard.writeText(exportData.toString());
  }
};

export const getExportMenu = ({
  fileName,
  exportType,
  sourceList,
  exportFields,
  header,
  processorName,
}) =>
  EXPORT_MENU_ITEMS.map(({ buttonText, fileExtension }) => ({
    text: buttonText,
    onClick: () =>
      exportTable(
        fileName,
        exportType,
        sourceList,
        exportFields,
        header,
        fileExtension,
        processorName,
      ),
  }));
