import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import clsx from 'clsx';

import { NUMBER_FORMATS } from 'utils/constants';
import { getFormattedNumber } from 'utils/helpers';

import styles from './styles.module.less';

const TableColCommissionEarned = ({
  currentPeriodCommission,
  commissionGrowth,
}) => {
  const style = useMemo(
    () => (commissionGrowth > 0 ? styles.positive : styles.negative),
    [commissionGrowth],
  );

  const changes = useMemo(
    () =>
      commissionGrowth !== undefined &&
      getFormattedNumber({
        number: commissionGrowth,
        style: NUMBER_FORMATS.percent,
        signDisplay: true,
      }),
    [commissionGrowth],
  );

  const profit = useMemo(
    () =>
      currentPeriodCommission !== undefined
        ? getFormattedNumber({
            number: currentPeriodCommission,
            style: NUMBER_FORMATS.currency,
            minimumFractionDigits: 2,
          })
        : '--',
    [currentPeriodCommission],
  );

  return (
    <div>
      <span>{profit}</span>
      {changes !== undefined && (
        <span className={clsx(style, styles.percent)}>{changes}</span>
      )}
    </div>
  );
};

TableColCommissionEarned.propTypes = {
  commissionGrowth: PropTypes.number,
  currentPeriodCommission: PropTypes.number,
};

export default TableColCommissionEarned;
